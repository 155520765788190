import React from "react"
import PropTypes from "prop-types"
import { PageRenderer, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade'
import he from 'he'

import Header from "../components/header"
import SEO from '../components/seo'

import "./profile.scss"

function Template({ data }) {
  const member = data && data.wpPerson

  const building = typeof window === "undefined"
  const [setPeoplePageData] = React.useState(!building && window.peoplePageData)

  React.useEffect(() => {
    window.setPeoplePageData = () => {
      if ( window.peoplePageData && typeof setPeoplePageData !== "undefined" ) setPeoplePageData(window.peoplePageData)
    }

    if (document) {
      document.body.style.maxHeight = "100vh"
      document.body.style.overflow = "hidden"
    }
    return () => {
      document.body.style.maxHeight = "unset"
      document.body.style.overflow = "auto"
    }
  }, [])

  return (
    <>
      <PageRenderer key={"/people"} location={{ pathname: "/people" }} />

      <div className="overlay-page">
        <SEO title={he.decode(member.seo.title)} bodyClass='' description={member.seo.metaDesc} />
        <Header closeAndNavigateTo="/people" />
        <div className="content">
          <div className="container">
            <Fade 
              bottom 
              distance='40px'
            >
              <div className="profile">
                <div className="profile__subtitle" dangerouslySetInnerHTML={{ __html: member.acf.intro}} />
              </div>
            </Fade>
          </div>
        </div>
        <div className="content">
          <div className="profile">
            <div className="profile__details">
              <Fade 
                bottom 
                distance='40px'
              >
                <div className="profile__image">
                  <Img
                    fluid={
                      member.acf.profilePicture.localFile.childImageSharp.fluid
                    }
                    alt={member.title}
                  />
                </div>
                <div
                  className="profile__info"
                  dangerouslySetInnerHTML={{ __html: member.acf.bio }}
                />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Template.propTypes = {
  data: PropTypes.object,
}

export const personQuery = graphql`
  query($id: String!) {
    wpPerson(id: { eq: $id }) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          sourceUrl
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        role
        intro
        profilePicture {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        bio
      }
    }
  }
`

export default Template
